import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import ServiceSidebar from '../components/ServiceSidebar'
import ServiceSMCard from '../components/ServiceSMCard'
import ServiceSupport from '../components/ServiceSupport'

const DevApp = () => {

    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
                <link rel="canonical" href="https://odidor.co/services/app-development" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />

                <title>Mobile Development Agency | App Development Firm</title>
                <meta name="description" content="Boasting some of the top mobile app developers in the Odidor  is a leading mobile app development firm offering a full suite of mobile application development services." />
                <meta name="keywords" content="mobile app development firm, mobile application development services, app development firms, app development agency, top mobile app developers, mobile app development agency, mobile development agency" />
                <meta property="og:title" content="Mobile Development Agency | App Development Firm" />
                <meta property="og:description"
                    content="Boasting some of the top mobile app developers in the world, Odidor  is a leading mobile app development firm offering a full suite of mobile application development services." />
                <meta property="og:type" content="Website" />
                <meta property="og:url" content="https://odidor.co/services/app-development" />
                <meta property="og:image" content="https://odidor.co/assets/img/services/AppDev.jpg" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:title" content="Mobile Development Agency | App Development Firm" />
                <meta name="twitter:description"
                    content="Boasting some of the top mobile app developers in the world, Odidor  is a leading mobile app development firm offering a full suite of mobile application development services." />
                <meta name="twitter:image" content="https://odidor.co/assets/img/services/AppDev.jpg" />
                <meta name="twitter:card" content="Website" />

                <meta property="og:site_name" content="Odidor " />
                <meta property="og:title" content="Mobile Development Agency | App Development Firm" />
                <meta property="og:type" content="Website" />
                <meta property="og:description"
                    content="Boasting some of the top mobile app developers in the world, Odidor  is a leading mobile app development firm offering a full suite of mobile application development services." />
                <meta property="og:image" content="https://odidor.co/assets/img/services/AppDev.jpg" />
                <meta property="og:url" content="https://odidor.co/services/app-development" />
            </Helmet>
            <main>
                <Header />
                <div class="bg-shape">
                    <img src="../assets/img/shape/shape-1.png" alt="Odidor  app development" />
                </div>

                <section class="page__title-area  pt-50">
                    <div class="container">
                        <div class="row">
                            <div class="col-xxl-12">
                                <div class="page__title-content mb-50">
                                    <h1 class="page__title">App Development Solutions</h1>
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li class="breadcrumb-item"><Link to="/services">Services</Link></li>
                                            <li class="breadcrumb-item active" aria-current="page">App Development </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="product__area pb-115">
                    <div class="container">
                        <div class="row">
                            <div class="col-xxl-8 col-xl-8 col-lg-8">
                                <div class="product__wrapper">

                                    <div class="product__details-thumb w-img mb-30">
                                        <img src="../assets/img/services/AppDev.jpg" alt="App Development" />
                                    </div>

                                    <div class="postbox__details mb-30">
                                        <p class="drop-cap">We combine cutting edge technology with immaculate design to create intuitive, user-friendly mobile app solutions that will surpass all expectations. What makes us different from other mobile app developers is our client-first approach to doing business. We are not taking your ideas and making them work within an existing framework, we are creating the framework to make your vision a reality. From ideation to iteration, our talented team is there every step of the way.
                                        </p>
                                    </div>

                                    <div class="postbox__details mb-30">
                                        <h2 class="mb-30">Our Mobile Tech Stack</h2>
                                        <h3>Native Android app development</h3>
                                        <p>Our team of Android mobile developers uses Kotlin when building native apps for Android devices. Kotlin is one of the official Android languages, backed by Google and supported by an active community and with in-depth documentation.
                                        </p>
                                        <div class="product__features mt-10">
                                            <h3 class="product__overview-title">You should choose a native Android app when</h3>
                                            <ol>
                                                <li>
                                                    <p>People in your audience use Android devices and they expect the best performance and user experience</p>
                                                </li>
                                                <li>
                                                    <p>You want to build a great foundation for product scaling</p>
                                                </li>
                                                <li>
                                                    <p>You build an app where you need precise control over the phone’s embedded functionality at the OS level</p>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>

                                    <div class="postbox__details mb-30">
                                        <h3>Native iOS app development</h3>
                                        <p>
                                            With Swift as the tech of choice for native development on iOS, we’re specialised in coding apps that not only work but can also be scaled fast. Backed by a mature and robust community of developers around the world, Swift is a reliable programming language for developing native iOS products, providing the best possible performance and all the options in terms of user experience.
                                        </p>
                                        <div class="product__features mt-10">
                                            <h3 class="product__overview-title">You should choose a native iOS app when</h3>
                                            <ol>
                                                <li>
                                                    <p>
                                                        Your users expect great performance and top-class UX from your app
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>You want to build a solid foundation for scalability</p>
                                                </li>
                                                <li>
                                                    <p>
                                                        You use heavily the phone’s embedded hardware, like sensors & trackers
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Your product is monetised through subscriptions or in-app payments instead of ad placements

                                                    </p>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>

                                    <div class="postbox__details mb-30">
                                        <h3>React Native app development</h3>
                                        <p>
                                            When you want to deal only with one code base but still reach both iOS and Android users, our technology of choice is React Native, as a cross-platform solution. Though a native implementation is still king in the
                                            majority of contexts, React Native is the best alternative for building some mobile apps (possibly) faster and without investing in too much code infrastructure from the start.
                                        </p>
                                        <div class="product__features mt-10">
                                            <h3 class="product__overview-title">We recommend going for a cross-platform product when:</h3>
                                            <ol>
                                                <li>
                                                    <p>
                                                        You want to launch an app fast & test it with both Android and iOS users
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>Your want to invest wisely in validate-first, build scalable later</p>
                                                </li>
                                                <li>
                                                    <p>
                                                        You build a simple, functional product where the responsiveness and interactivity of the experience are not essential or tackle use cases where “offline” usage is not key.
                                                    </p>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>

                                    <div class="postbox__details mb-30">
                                        <h3>Flutter mobile app development</h3>
                                        <p>
                                            We recommend Flutter as an alternative cross-platform solution when you need to launch something fast into the market, using a technology framework that gives you a good head start, with its ready-to-go widgets. The main concern here remains the fact that being a young technology, the community is smaller and the ecosystem still has a bit of maturing to do.
                                        </p>
                                    </div>

                                    <div class="postbox__quote-2 mb-50">
                                        <blockquote>
                                            <h4>“Measuring programming progress by lines of code is like measuring aircraft building progress by weight.”
                                            </h4>
                                            <span>- Bill Gates</span>
                                        </blockquote>
                                    </div>

                                    <div class="product__details-content">
                                        <div class="product__tab mb-40">
                                            <ul class="nav nav-tabs" id="proTab" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true">Overview</button>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link" id="support-tab" data-bs-toggle="tab" data-bs-target="#support" type="button" role="tab" aria-controls="support" aria-selected="false">Support</button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="product__tab-content">
                                            <div class="tab-content" id="proTabContent">
                                                <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                                    <div class="product__overview">
                                                        <h3 class="product__overview-title">Our Vision & Specs</h3>
                                                        <p class="drop-cap">We combine cutting edge technology with immaculate design to create intuitive, user-friendly mobile app solutions that will surpass all expectations. What makes us different from other mobile app developers is our client-first approach to doing business. We are not taking your ideas and making them work within an existing framework, we are creating the framework to make your vision a reality. From ideation to iteration, our talented team is there every step of the way.</p>
                                                        <ServiceSMCard />
                                                    </div>
                                                </div>
                                                <ServiceSupport />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ServiceSidebar />
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
        </>
    )
}

export default DevApp