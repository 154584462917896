import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import BlogSingle from '../components/BlogSingle'
import Footer from '../components/Footer'
import Header from '../components/Header'

const Blog = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
                <link rel="canonical" href="https://splendid.productions/Blog" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />

                <title>Tech Blog - Odidor </title>
                <meta name="description" content="Odidor  blogs about code, Agile, web development, startups, Ruby on Rails, Node.js, React Native, mobile, remote work & more." />
                <meta name="keywords" content="code, Agile, web development, startups, Ruby on Rails, Node.js, React Native, mobile, remote work & more." />
                <meta property="og:title" content="Tech Blog - Odidor " />
                <meta property="og:description"
                    content="Odidor  blogs about code, Agile, web development, startups, Ruby on Rails, Node.js, React Native, mobile, remote work & more." />
                <meta property="og:type" content="Website" />
                <meta property="og:url" content="https://splendid.productions/Blog" />
                <meta property="og:image" content="https://splendid.productions/assets/img/services/AppDev.jpg" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:title" content="Tech Blog - Odidor " />
                <meta name="twitter:description"
                    content="Odidor  blogs about code, Agile, web development, startups, Ruby on Rails, Node.js, React Native, mobile, remote work & more." />
                <meta name="twitter:image" content="https://splendid.productions/assets/img/services/AppDev.jpg" />
                <meta name="twitter:card" content="Website" />

                <meta property="og:site_name" content="Odidor " />
                <meta property="og:title" content="Tech Blog - Odidor " />
                <meta property="og:type" content="Website" />
                <meta property="og:description"
                    content="Odidor  blogs about code, Agile, web development, startups, Ruby on Rails, Node.js, React Native, mobile, remote work & more." />
                <meta property="og:image" content="https://splendid.productions/assets/img/services/AppDev.jpg" />
                <meta property="og:url" content="https://splendid.productions/Blog" />
            </Helmet>
            <main>
                <Header />
                {/* <section class="postbox__area pb-120">
                    <div class="container">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12">
                                <div class="postbox__wrapper">
                                    <BlogSingle />
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <iframe src={'https://pwoly.com/'} style={{ width: "100%", height: "100vh" }} />
                <Footer />
            </main>
        </>
    )
}

export default Blog