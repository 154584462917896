import React from 'react'
import { Link } from 'react-router-dom'

const MainBanner = () => {
    return (
        <section class="hero__area hero__height grey-bg-3 d-flex align-items-center">
            <div class="hero__shape">
                <img class="circle" src="assets/img/icon/hero/hero-circle.png" alt="circle" />
                <img class="circle-2" src="assets/img/icon/hero/hero-circle-2.png" alt="circle" />
                <img class="square" src="assets/img/icon/hero/hero-square.png" alt="circle" />
                <img class="square-2" src="assets/img/icon/hero/hero-square-2.png" alt="circle" />
                <img class="dot" src="assets/img/icon/hero/hero-dot.png" alt="circle" />
                <img class="triangle" src="assets/img/icon/hero/hero-triangle.png" alt="circle" />
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-8">
                        <div class="hero__content">
                            <h2 class="hero__title">
                                BEST<span> TECH </span><br />
                                <span> BEST</span> FUTURE <br />
                            </h2>
                            <p>We combine cutting edge technology with immaculate design to create intuitive, user-friendly mobile app solutions that will surpass all expectations.</p>
                            <div class="hero__search">
                                <form action="#">
                                    <div class="hero__search-inner d-xl-flex">
                                        {/* <div class="hero__search-input">
                                            <span><i class="far fa-search"></i></span>
                                            <input type="text" placeholder="Search for templates" />
                                        </div> */}
                                        {/* <button type="submit" class="m-btn ml-20"> <span></span> search</button> */}
                                        <Link to="/services/app-development" class="s-btn ml-0"> <span></span> App Dev</Link>
                                        <Link to="/services/app-development" class="s-btn ml-5"> <span></span>| Web Dev</Link>
                                        <Link to="/services/app-development" class="s-btn ml-5"> <span></span>| Software Dev</Link>
                                        <Link to="/services/app-development" class="s-btn ml-5"> <span></span>| Digital Marketing</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div class="hero__thumb-wrapper scene ml-70">
                            <div class="hero__thumb one d-none d-lg-block">
                                <img class="layer" data-depth="0.2" src="assets/img/banner/01.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MainBanner