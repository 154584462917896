import React from 'react'
import { Link } from 'react-router-dom'

const ServiceCard = () => {
    return (
        <section class="category__area pt-105 pb-60">
            <div class="container">
                <div class="row">
                    <div class="col-xxl-12">
                        <div class="section__title-wrapper text-center mb-60">
                            <h1 class="section__title">Browse <br /> Our Services</h1>
                            <p>Odidor: Your one-stop solution for comprehensive web and software services. We specialize in digital marketing, custom web development, mobile app creation, and software development. Our expert team delivers tailored, innovative solutions to boost your online presence and drive business growth. From SEO strategies to cutting-edge mobile apps, Odidor transforms your digital vision into reality. Partner with us for powerful, results-driven web solutions that set you apart in the digital landscape.</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xxl-3 col-xl-3 col-md-6 col-sm-6">
                        <div class="category__item transition-3 text-center white-bg mb-30 wow fadeInUp" data-wow-delay=".3s">
                            <div class="category__icon mb-25">
                                <Link to="/services/app-development"><img src="assets/img/icon/category/cat-1.png" alt="App Development" /></Link>
                            </div>
                            <div class="category__content">
                                <h3 class="category__title">
                                    <Link to="/services/app-development">App Development</Link>
                                </h3>
                                <Link to="/services/app-development" class="link-btn">
                                    <i class="far fa-long-arrow-right"></i>
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-3 col-xl-3 col-md-6 col-sm-6">
                        <div class="category__item transition-3 text-center white-bg mb-30 wow fadeInUp" data-wow-delay=".5s">
                            <div class="category__icon mb-25">
                                <Link to="/services/website-development"><img src="assets/img/icon/category/cat-2.png" alt="Web Development" /></Link>
                            </div>
                            <div class="category__content">
                                <h3 class="category__title">
                                    <Link to="/services/website-development">Web Development</Link>
                                </h3>
                                <Link to="/services/website-development" class="link-btn">
                                    <i class="far fa-long-arrow-right"></i>
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-3 col-xl-3 col-md-6 col-sm-6">
                        <div class="category__item transition-3 text-center white-bg mb-30 wow fadeInUp" data-wow-delay=".7s">
                            <div class="category__icon mb-25">
                                <Link to="/services/software-development"><img src="assets/img/icon/category/cat-3.png" alt="/Software Development" /></Link>
                            </div>
                            <div class="category__content">
                                <h3 class="category__title">
                                    <Link to="/services/software-development">Software Dev</Link>
                                </h3>
                                <Link to="/services/software-development" class="link-btn">
                                    <i class="far fa-long-arrow-right"></i>
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-3 col-xl-3 col-md-6 col-sm-6">
                        <div class="category__item transition-3 text-center white-bg mb-30 wow fadeInUp" data-wow-delay=".9s">
                            <div class="category__icon mb-25">
                                <Link to="/services/digital-marketing"><img src="assets/img/icon/category/cat-4.png" alt="/Digital Marketing" /></Link>
                            </div>
                            <div class="category__content">
                                <h3 class="category__title">
                                    <Link to="/services/digital-marketing">Digital Marketing</Link>
                                </h3>
                                <Link to="/services/digital-marketing" class="link-btn">
                                    <i class="far fa-long-arrow-right"></i>
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="row">
                    <div class="col-xxl-12">
                        <div class="category__more mt-30 text-center">
                            <Link to="product.html" class="m-btn m-btn-2"> <span></span> View all categories</Link>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
    )
}

export default ServiceCard