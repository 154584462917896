import React from 'react'
import { Link } from 'react-router-dom'

const ServiceSidebar = () => {
    return (
        <div class="col-xxl-4 col-xl-4 col-lg-4">
            <div class="product__details-sidebar ml-30">
                <div class="product__proprietor white-bg mb-30">
                    <div class="product__proprietor-head mb-25">
                        <div class="product__proprietor-text">
                            <p><b>Best Tech, Best Future</b> <br />- Odidor </p>
                        </div>
                    </div>
                    <div class="product__proprietor-body fix mb-30">
                        <ul class="mb-10 fix">
                            <li>
                                <Link to="/services/app-development">
                                    <span>App Dev:</span>
                                    <h6>Native dev</h6>
                                    <h6>React Native</h6>
                                    <h6>Flutter</h6>
                                </Link>
                            </li>
                            <li>
                                <Link to="/services/website-development">
                                    <span>Web Dev:</span>
                                    <h6>Web Apps</h6>
                                    <h6>CMS </h6>
                                    <h6>Open Source</h6>
                                </Link>
                            </li>
                            <li>
                                <Link to="/services/software-development">
                                    <span>Software Dev:</span>
                                    <h6>POS System</h6>
                                    <h6>HRMS</h6>
                                </Link>
                            </li>
                            <li>
                                <Link to="/services/digital-marketing">
                                    <span>Digital Marketing:</span>
                                    <h6>SEO Services</h6>
                                    <h6>PPC</h6>
                                    <h6>SMM</h6>
                                </Link>
                            </li>
                        </ul>
                        <Link to="/Contact" class="m-btn m-btn-2 w-100 mb-20"> <span></span> Get A Quote</Link>
                        <a href="mailto:ryan@odidor.co" target="_blank" rel="noreferrer" class="m-btn m-btn-border w-100"> <span></span> Email Us</a>
                    </div>
                    <div class="sidebar__widget mb-20">
                        <div class="sidebar__widget-title">
                            <h3>Categories</h3>
                        </div>
                        <div class="sidebar__widget-content">
                            <div class="sidebar__catagory">
                                <ul>
                                    <li><Link to="/services/app-development">App Design </Link></li>
                                    <li><Link to="/services/website-development"> Web Development </Link></li>
                                    <li>&emsp;<Link to="/services/website-development/ecommerce-web-development"> E-Commerce Dev </Link></li>
                                    <li>&emsp;<Link to="/services/website-development/web-app-development"> Web App Dev </Link></li>
                                    <li><Link to="/services/software-development">Software Development </Link></li>
                                    <li><Link to="/services/digital-marketing">Digital Marketing </Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceSidebar