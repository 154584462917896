import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import ServiceSidebar from '../../components/ServiceSidebar'
import ServiceSMCard from '../../components/ServiceSMCard'
import ServiceSupport from '../../components/ServiceSupport'

const DevWeb = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
                <link rel="canonical" href="https://odidor.co/WebDevelopment" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />

                <title>Website Design & development Agency, Custom Website Development</title>
                <meta name="description" content="We provide impressive and effective Website Design, Responsive Web Design solutions for a wider reach and online exposure. For more call us at 604-363-3150" />
                <meta name="keywords" content="Web development, Business development, web designs and custom website  development, E-commerce web development and solutions, SEO and digital marketing solutions, CMS & Backend development" />
                <meta property="og:title" content="Website Design & development Agency, Custom Website Development" />
                <meta property="og:description"
                    content="We provide impressive and effective Website Design, Responsive Web Design solutions for a wider reach and online exposure. For more call us at 604-363-3150" />
                <meta property="og:type" content="Website" />
                <meta property="og:url" content="https://odidor.co/WebDevelopment" />
                <meta property="og:image" content="https://odidor.co/assets/img/services/WebDev.jpg" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:title" content="Website Design & development Agency, Custom Website Development" />
                <meta name="twitter:description"
                    content="We provide impressive and effective Website Design, Responsive Web Design solutions for a wider reach and online exposure. For more call us at 604-363-3150" />
                <meta name="twitter:image" content="https://odidor.co/assets/img/services/WebDev.jpg" />
                <meta name="twitter:card" content="Website" />

                <meta property="og:site_name" content="Odidor " />
                <meta property="og:title" content="Website Design & development Agency, Custom Website Development" />
                <meta property="og:type" content="Website" />
                <meta property="og:description"
                    content="We provide impressive and effective Website Design, Responsive Web Design solutions for a wider reach and online exposure. For more call us at 604-363-3150" />
                <meta property="og:image" content="https://odidor.co/assets/img/services/WebDev.jpg" />
                <meta property="og:url" content="https://odidor.co/WebDevelopment" />
            </Helmet>
            <main>
                <Header />
                <div className="bg-shape">
                    <img src="../assets/img/shape/shape-1.png" alt="Odidor Web development" />
                </div>

                <section className="page__title-area  pt-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="page__title-content mb-50">
                                    <h1 className="page__title">Web Development Solutions</h1>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item"><Link to="/services">Services</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Web Development </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="product__area pb-115">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-8 col-xl-8 col-lg-8">
                                <div className="product__wrapper">

                                    <div className="product__details-thumb w-img mb-30">
                                        <img src="../assets/img/services/WebDev.jpg" alt="Web Development" />
                                    </div>

                                    <div className="postbox__details mb-30">
                                        <p className="drop-cap">We provide effective website development, testing, and maintenance services for startups and established businesses in Vancouver and across the globe. Our deep understanding and experience with custom web development allow us to create websites that lead to enhanced engagement and higher conversions. We work collaboratively with our clients throughout the development process, from concept to completion, to ensure that your business website requirements are fully realized.
                                        </p>
                                    </div>

                                    <div className="postbox__details mb-30">
                                        <h2 className="mb-30"> Web Development Core Services</h2>
                                        <h3>Custom Web Development</h3>
                                        <p>We have expert web developers and coders adept in working on varying development platforms such as Drupal, WordPress, Magento, PHP, Joomla, and more. We specialize in custom web development services, as we understand that every project’s requirements are unique and will be best served by a custom solution. As a client-oriented web development agency, we offer web solutions that complement the budget and objectives of each client’s business, and we prioritize client satisfaction. To know more click here
                                        </p>
                                        <div className="product__features mt-10">
                                            <h3 className="product__overview-title">Our Web Development Expertise:</h3>
                                            {/* Ecommerce Development */}
                                            <div className="col-12">
                                                <a href="/services/website-development/ecommerce-web-development" title='Ecommerce Development'>
                                                    <div className="documentation__item gradient-pink mb-30 text-center">
                                                        <div className="documentation__icon mb-30">
                                                            <i class="fas fa-shopping-cart fa-4x text-light"></i>
                                                        </div>
                                                        <div className="documentation__content">
                                                            <h3 className="documentation__title">
                                                                <a href="/services/website-development/ecommerce-web-development" title='Ecommerce Development'>E-commerce Development</a>
                                                            </h3>
                                                            <p className="text-light">In today’s digital age, a robust and dynamic e-commerce platform is essential for any business aiming to thrive online. At Odidor, we specialize in comprehensive e-commerce development that transforms your online presence and enhances the shopping experience for your customers.</p>
                                                            <a href="/services/website-development/ecommerce-web-development" title='Ecommerce Development' className="m-btn m-btn-border text-light mt-20 rounded-pill"> Explore More </a>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            {/* Web Development */}
                                            <div className="col-12">
                                                <a href="/services/website-development/web-app-development" title='Web Apps Development'>
                                                    <div className="documentation__item gradient-blue mb-30 text-center">
                                                        <div className="documentation__icon mb-30">
                                                            <i class="fas fa-desktop fa-4x text-light"></i>
                                                        </div>
                                                        <div className="documentation__content">
                                                            <h3 className="documentation__title">
                                                                <a href="/services/website-development/web-app-development" title='Web apps Development'>Web App Development</a>
                                                            </h3>
                                                            <p className="text-light">Web application is application software that runs on a web server, unlike computer-based software programs that are run locally on the operating system of the device. Web applications are accessed by the user through a web browser with an active network connection.</p>
                                                            <a href="/services/website-development/web-app-development" title='Web Apps Development' className="m-btn m-btn-border text-light mt-20 rounded-pill"> Explore More </a>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            {/* Custom CMS */}
                                            <div className="col-12">
                                                <a href="/services/website-development" title='CMS Development'>
                                                    <div className="documentation__item gradient-orange mb-30 text-center">
                                                        <div className="documentation__icon mb-30">
                                                            <i class="fas fa-shapes fa-4x text-light"></i>
                                                        </div>
                                                        <div className="documentation__content">
                                                            <h3 className="documentation__title">
                                                                <a href="/services/website-development" title='CMS Development'>Custom CMS Development</a>
                                                            </h3>
                                                            <p className="text-light">A web content management system (WCM or WCMS) is a software content management system (CMS) specifically for web content. It provides website authoring, collaboration, and administration tools that help users with little knowledge of web programming languages or markup languages create and manage website content.</p>
                                                            <a href="/services/website-development" title='CMS Development' className="m-btn m-btn-border text-light mt-20 rounded-pill"> Explore More </a>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            {/* Dev Ops */}
                                            <div className="col-12">
                                                <a href="/services/website-development" title='Open Source Development'>
                                                    <div className="documentation__item gradient-purple mb-30 text-center">
                                                        <div className="documentation__icon mb-30">
                                                            <i class="fab fa-osi fa-4x text-light"></i>
                                                        </div>
                                                        <div className="documentation__content">
                                                            <h3 className="documentation__title">
                                                                <a href="/services/website-development" title='Open Source Development'>Open Source Dev</a>
                                                            </h3>
                                                            <p className="text-light">Open source technologies allow for affordable web solutions and enable customizations as needed to meet unique project requirements.</p>
                                                            <a href="/services/website-development" title='Open Source Development' className="m-btn m-btn-border text-light mt-20 rounded-pill"> Explore More </a>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>


                                        </div>
                                    </div>

                                    <div className="postbox__details mb-30" id="sectionqw">
                                        <h3>Elevated Web Design</h3>
                                        <p>
                                            We combine exciting, modern design elements with intuitive UI/UX layouts to deliver effective websites that are visually engaging, user-friendly, and interactive. With a focus on providing your end users with a seamless experience and clear information, we design web experiences that reflect your brand and lead to increased engagement and higher conversions.
                                            <br />
                                            Our experienced design team seeks to understand your business objectives and create website that will inspire your audience and deliver your message. A well-designed website enhances your brand online and provides the foundation for your promotional efforts. We skillfully incorporate vital web features, such as interactive media, calls to action, conversion points, and social media integration to ensure that your website works to connect you with your target users.
                                        </p>

                                        <div className="product__features mt-10">
                                            <h3 className="product__overview-title">Developing a Web Design for different platforms</h3>
                                            <ol>
                                                <li>
                                                    <p><b>Interactive Web Design</b> :  web application is application software that runs on a web server, unlike computer-based software programs that are run locally on the operating system of the device. Web applications are accessed by the user through a web browser with an active network connection.</p>
                                                </li>
                                                <li>
                                                    <p><b>Custom CMS</b> : A web content management system (WCM or WCMS) is a software content management system (CMS) specifically for web content. It provides website authoring, collaboration, and administration tools that help users with little knowledge of web programming languages or markup languages create and manage website content.</p>
                                                </li>
                                                <li>
                                                    <p><b>Open Source Dev</b> : Open source technologies allow for affordable web solutions and enable customizations as needed to meet unique project requirements.</p>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>

                                    <div className="postbox__details mb-30">
                                        <h3>Blockchain Development</h3>
                                        <p>
                                            We combine exciting, modern design elements with intuitive UI/UX layouts to deliver effective websites that are visually engaging, user-friendly, and interactive. With a focus on providing your end users with a seamless experience and clear information, we design web experiences that reflect your brand and lead to increased engagement and higher conversions.
                                            <br />
                                            Our experienced design team seeks to understand your business objectives and create website that will inspire your audience and deliver your message. A well-designed website enhances your brand online and provides the foundation for your promotional efforts. We skillfully incorporate vital web features, such as interactive media, calls to action, conversion points, and social media integration to ensure that your website works to connect you with your target users.
                                        </p>

                                        <div className="product__features mt-10">
                                            <h3 className="product__overview-title">Blockchain Transformation Enablers </h3>
                                            <ol>
                                                <li>
                                                    <p><b>Asynchronous Systems</b> : Decentralized independent processes allow for vastly improved transaction time.</p>
                                                </li>
                                                <li>
                                                    <p><b>Crypto Exchanges</b> : Allow for expanded reach and reduced transaction fees with multiple crypto and fiat currencies.</p>
                                                </li>
                                                <li>
                                                    <p><b>Blockchain Maintenance</b> : Qualified support and continual monitoring will ensure your blockchain performs and grows with your business.</p>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>

                                    <div className="postbox__quote-2 mb-50">
                                        <blockquote>
                                            <h4>“Websites promote you 24/7: No employee will do that.”
                                            </h4>
                                            <span>- Paul Cookson</span>
                                        </blockquote>
                                    </div>

                                    <div className="product__details-content">
                                        <div className="product__tab mb-40">
                                            <ul className="nav nav-tabs" id="proTab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true">Overview</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="support-tab" data-bs-toggle="tab" data-bs-target="#support" type="button" role="tab" aria-controls="support" aria-selected="false">Support</button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="product__tab-content">
                                            <div className="tab-content" id="proTabContent">
                                                <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                                    <div className="product__overview">
                                                        <h3 className="product__overview-title">Our Vision & Specs</h3>
                                                        <p className="drop-cap">We specialize in custom web development services, as we understand that every project’s requirements are unique and will be best served by a custom solution. As a client-oriented web development agency, we offer web solutions that complement the budget and objectives of each client’s business, and we prioritize client satisfaction.</p>
                                                        <ServiceSMCard />
                                                    </div>
                                                </div>
                                                <ServiceSupport />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ServiceSidebar />
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
        </>
    )
}

export default DevWeb